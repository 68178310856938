import { List, TagField, useTable, EditButton, DeleteButton } from "@refinedev/antd";
import { Table, Space } from "antd";

import {ISeller} from 'interfaces';
import React from 'react';

export const SellerList: React.FC = () => {
    const { tableProps, setFilters } = useTable<ISeller>({
        resource: "rivenditoris"
    });

    return (
        <List title={'Rivenditori'}>
            <Table {...tableProps} size={"small"} rowKey="id">
                <Table.Column
                    dataIndex="nome"
                    title="Nome"
                    render={(value) => <TagField color={"green"} value={value} />}
                />
                <Table.Column
                    dataIndex="cognome"
                    title="Cognome"
                    render={(value) => <TagField color={"red"} value={value} />}
                />
                <Table.Column
                    dataIndex="email"
                    title="Email"
                />
                <Table.Column
                    dataIndex="telefono"
                    title="Telefono"
                />
                <Table.Column<ISeller>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};

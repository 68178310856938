import "@refinedev/antd/dist/reset.css";
import "@refinedev/antd";
import "./styles.css";

import { Authenticated, Refine } from "@refinedev/core";
import { ErrorComponent, useNotificationProvider, ThemedLayoutV2 } from "@refinedev/antd";
import { DataProvider } from "@refinedev/strapi-v4";
import { useTranslation } from 'react-i18next';
import { Header } from 'components/layout';
import { authProvider, axiosInstance } from './authProvider';
import { API_URL } from './constants';
import { DashboardList } from './pages';
import { ParametriList } from './pages/parametris';
import { OrderedListOutlined, SettingOutlined, DashboardOutlined } from '@ant-design/icons';
import { Login } from './pages/login';
import { DocumentiList } from './pages/documentis';
import { OrdiniList } from './pages/ordinis';
import { OrdiniEdit } from './pages/ordinis/edit';
import { FornitoriList } from './pages/fornitoris';
import { UserCreate, UserEdit, UserList } from './pages/users';
import { ArticoliList } from './pages/articolis';
import { newEnforcer } from 'casbin';
import { model, adapter } from "accessControl";
import { apiFunction } from './apiFuntion';
import { SellerCreate, SellerEdit, SellerList } from './pages/rivenditoris';
import { StoreProvider } from './contexts/StoreContext';
import { ListinoList } from './pages/listinos';
import { ListinoEdit } from './pages/listinos/edit';
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import routerProvider, { CatchAllNavigate, NavigateToResource } from "@refinedev/react-router-v6";
import Title from "components/layout/title/Title";
import { ConfigProvider, App as AntdApp, theme } from "antd";
import Sider from "components/layout/sider/Sider";
import { ThemeConfig } from "antd/lib";

// export const socket = io(SOCKET_URL);


function App() {
    const { t, i18n } = useTranslation();
    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    const myTheme: ThemeConfig = {
        algorithm: theme.defaultAlgorithm,
        token: {
            colorPrimary: '#3F96FE',
        },
    };


    return (
        (
            <BrowserRouter>
                <ConfigProvider theme={myTheme} >
                    <AntdApp >
                        <StoreProvider>
                            <Refine
                                accessControlProvider={{
                                    can: async ({ resource, action, params }) => {
                                        const role = await apiFunction.permission();
                                        const enforcer = await newEnforcer(model, adapter);
                                        const can = await enforcer.enforce(
                                            role,
                                            resource,
                                            action,
                                        );
                                        return Promise.resolve({ can });
                                    },
                                }}
                                notificationProvider={useNotificationProvider}
                                authProvider={authProvider}
                                routerProvider={routerProvider}
                                dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
                                i18nProvider={i18nProvider}
                                resources={[
                                    { name: 'dashboards', list: DashboardList, meta: { label: 'Dashboard' }, icon: <DashboardOutlined /> },
                                    {
                                        name: 'rivenditoris',
                                        list: "/rivenditoris",
                                        create: "/rivenditoris/create",
                                        edit: "/rivenditoris/edit/:id",
                                        meta: { label: 'Rivenditori', }, icon: <OrderedListOutlined />
                                    },
                                    // { name: 'prodottis', list: '/prodottis', meta: { label: 'Prodotti' }, icon: <OrderedListOutlined /> },
                                    {
                                        name: 'listinos',
                                        list: '/listinos',
                                        edit: '/listinos/edit/:id',
                                        meta: { label: 'Listino' }, icon: <OrderedListOutlined />
                                    },
                                    { name: 'articolis', list: '/articolis', meta: { label: 'Articoli' }, icon: <OrderedListOutlined /> },
                                    { name: 'documentis', list: '/documentis', meta: { label: 'Documenti' }, icon: <OrderedListOutlined /> },
                                    { name: 'fornitoris', list: '/fornitoris', meta: { label: 'Fornitori' }, icon: <OrderedListOutlined /> },
                                    {
                                        name: 'ordinis',
                                        edit: OrdiniEdit,
                                        list: OrdiniList,
                                        meta: { label: 'Nuovo Ordine' },
                                        icon: <OrderedListOutlined />
                                    },
                                    // { name: "listino-riferimentos", list: ListinoRiferimentoList, options: {label: 'Listino Rif'}, icon: <OrderedListOutlined />},
                                    { name: 'parametri', list: '/parametris', meta: { label: 'Parametri' }, icon: <SettingOutlined /> },
                                    { name: 'users', edit: '/users/edit/:id', create: '/users/create', list: '/users', meta: { label: 'Utenti', canDelete: true }, icon: <SettingOutlined /> },
                                ]}
                            >

                                <Routes>
                                    <Route
                                        element={
                                            <Authenticated
                                                key="authenticated-routes"
                                                fallback={<CatchAllNavigate to="/login" />}
                                            >
                                                <ThemedLayoutV2 Sider={Sider} Header={Header} Title={(props) => <Title collapsed={props.collapsed} />} >
                                                    <Outlet />
                                                </ThemedLayoutV2>
                                            </Authenticated>
                                        }
                                    >
                                        <Route
                                            index
                                            element={<NavigateToResource resource="dashboards" />}
                                        />

                                        <Route path="/dashboards">
                                            <Route index element={<DashboardList />} />
                                        </Route>

                                        <Route path="/rivenditoris">
                                            <Route index element={<SellerList />} />
                                            <Route path="create" element={<SellerCreate />} />
                                            <Route path="edit/:id" element={<SellerEdit />} />
                                        </Route>

                                        <Route path="/listinos">
                                            <Route index element={<ListinoList />} />
                                            <Route path="edit/:id" element={<ListinoEdit />} />
                                        </Route>


                                        <Route path="/articolis">
                                            <Route index element={<ArticoliList />} />
                                        </Route>

                                        <Route path="/documentis">
                                            <Route index element={<DocumentiList />} />
                                        </Route>

                                        <Route path="/fornitoris">
                                            <Route index element={<FornitoriList />} />
                                        </Route>

                                        <Route path="/ordinis">
                                            <Route index element={<OrdiniList />} />
                                            <Route path="edit/:id" element={<OrdiniEdit />} />
                                        </Route>

                                        <Route path="/parametris">
                                            <Route index element={<ParametriList />} />
                                        </Route>

                                        <Route path="/users">
                                            <Route index element={<UserList />} />
                                            <Route path="create" element={<UserCreate />} />
                                            <Route path="edit/:id" element={<UserEdit />} />
                                        </Route>

                                    </Route>

                                    <Route
                                        element={
                                            <Authenticated key="auth-pages" fallback={<Outlet />}>
                                                <NavigateToResource resource="dashboards" />
                                            </Authenticated>
                                        }
                                    >
                                        <Route
                                            path="/login"
                                            element={
                                                <Login />
                                            }
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <Authenticated key="catch-all">
                                                <ThemedLayoutV2>
                                                    <Outlet />
                                                </ThemedLayoutV2>
                                            </Authenticated>
                                        }
                                    >
                                        <Route path="*" element={<ErrorComponent />} />
                                    </Route>
                                </Routes>
                            </Refine>
                        </StoreProvider>
                    </AntdApp>
                </ConfigProvider>
            </BrowserRouter>
        )
    );
}

export default App;

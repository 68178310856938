import { newModel, StringAdapter } from "casbin";

export const model = newModel(`
[request_definition]
r = sub, obj, act
[policy_definition]
p = sub, obj, act, eft
[role_definition]
g = _, _
[policy_effect]
e = some(where (p.eft == allow)) && !some(where (p.eft == deny))
[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new StringAdapter(`
p, user, dashboards, (list)
p, user, articolis, (list)|(edit)
p, user, documentis, (list)|(edit)
p, user, fornitoris, (list)
p, user, ordinis, (list)|(edit)
p, user, parametri, (list)
p, seller, dashboards, (list)
p, seller, articolis, (list)|(edit)
p, seller, documentis, (list)|(edit)
p, seller, fornitoris, (list)
p, seller, ordinis, (list)|(edit)
p, seller, parametri, (list)
p, user, parametri, (list)
p, seller, users, (create)|(list)|(edit)|(show)
p, admin, rivenditoris, (create)|(list)|(edit)|(show)|(delete)
p, admin, users, (list)|(create)|(edit)
p, admin, users/*, (edit)|(show)|(delete)
p, admin, dashboards, (list)
p, admin, articolis, (list)|(edit)
p, admin, documentis, (list)|(edit)
p, admin, fornitoris, (list)
p, admin, ordinis, (list)|(edit)
p, admin, parametri, (list)
p, admin, listinos, (list)|(edit)
`);

import { API_URL, TOKEN_KEY } from "./constants";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { MeOptions } from "@refinedev/strapi-v4/src/helpers/auth";
export const axiosInstance = axios.create();
export const apiFunction: any = {
  permission: async (options?: MeOptions) => {
    if (localStorage.getItem(TOKEN_KEY)) {
      const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || "");
      return decoded && decoded.user?.gruppo?.isAdmin
        ? "admin"
        : decoded.user?.gruppo?.isRivenditore
        ? "seller"
        : decoded.user?.gruppo?.isUtente
        ? "user"
        : "user";
    } else {
      return "user";
    }
  },
  getUserData: async () => {
    if (localStorage.getItem(TOKEN_KEY)) {
      const decoded: any = jwt_decode(localStorage.getItem(TOKEN_KEY) || "");
      return decoded.user;
    } else {
      return [];
    }
  },
  setSocketId: async (token?: string, id?: number, socketId?: string) => {
    if (token) {
      return await axios.put(
        API_URL + "/api/users/" + id,
        {
          socketId: socketId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      return false;
    }
  },
  resetPassword: async (
    code: any,
    password: any,
    passwordConfirmation: any
  ) => {
    if (code && password && passwordConfirmation) {
      return await axios.post(
        API_URL + "/api/auth/reset-password",
        {
          code: code,
          password: password,
          passwordConfirmation: passwordConfirmation,
        },
        { headers: { "Content-Type": "application/json" } }
      );
    } else {
      return false;
    }
  },
  resetLinkPassword: async (email: string) => {
    if (email) {
      return await axios.post(
        API_URL + "/api/auth/forgot-password",
        {
          email: email,
        },
        { headers: { "Content-Type": "application/json" } }
      );
    } else {
      return false;
    }
  },
  getListino: async () => {
    let d: any = "";
    if (localStorage.getItem(TOKEN_KEY)) {
      const listino = await axiosInstance.get(
        API_URL +
          "/api/crons?filters[isImported][$eq]=1&pagination[page]=1&pagination[pageSize]=99999",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
          },
        }
      );
      if (listino && listino.data && listino.data.data.length > 0) {
        const i = Math.max(...listino.data.data.map((o: any) => o.id));
        d = listino.data.data.filter((x: any) => x.id === i);
      }
      console.log("Ultimo Listino: ", d[0].attributes.updatedAt);
      return d[0].attributes.updatedAt;
    }
  },
};

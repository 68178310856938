import { List, TagField, useTable, EditButton, DeleteButton, DateField } from "@refinedev/antd";
import { Table, Space } from "antd";

import { IUser } from 'interfaces';
import React, { useContext } from 'react';
import { StoreContext } from '../../contexts/StoreContext';

export const UserList: React.FC = () => {
    const { value, value2 } = useContext(StoreContext);
    const [store, setStore] = value;
    const [user, setUser] = value2;
    const { tableProps, setFilters } = useTable<IUser>({
        resource: "users",
        meta: {
            populate: "gruppo, rivenditori, rifLicenzaEmessa, rifLicenzaEmessa.rifTipoLicenza",
        },

        filters: {
            permanent: [
                {
                    field: 'rivenditori][email]',
                    operator: 'eq',
                    value: (user.gruppo?.isAdmin ? null : user.email)
                }
            ]
        }
    });

    const handleChange = (d: any) => {
        console.log(d);
        setFilters(
            [{
                field: 'user][id]',
                operator: 'eq',
                value: d
            }])
    }

    return (
        <List title={'Utenti'}>
            <Table {...tableProps} size={"small"} rowKey="id">
                <Table.Column
                    dataIndex="nome"
                    title="Nome"
                    render={(value) => <TagField color={"green"} value={value} />}
                />
                <Table.Column
                    dataIndex="cognome"
                    title="Cognome"
                    render={(value) => <TagField color={"red"} value={value} />}
                />
                <Table.Column
                    dataIndex="email"
                    title="Email"
                />
                <Table.Column
                    dataIndex="gruppo"
                    title="Gruppo"
                    render={(value) => <TagField color={"blue"} value={value?.descrizione} />}
                />
                <Table.Column
                    dataIndex="rifLicenzaEmessa"
                    title="Tipo Licenza"
                    render={(value) => {
                        return <TagField color={"red"} value={value[0]?.rifTipoLicenza?.descLicenza} />
                    }}
                />
                <Table.Column
                    dataIndex="validaDal"
                    title="Data Attivazione"
                    render={(value, record: any) => {
                        return <DateField value={record['rifLicenzaEmessa'][0]?.validaDal} format={'DD/MM/YYYY'}></DateField>
                    }}
                />
                <Table.Column
                    dataIndex="validaAl"
                    title="Data Scadenza"
                    render={(value, record: any) => {
                        return <DateField value={record['rifLicenzaEmessa'][0]?.validaAl} format={'DD/MM/YYYY'}></DateField>
                    }}
                />
                <Table.Column<IUser>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    size="small"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};

import { useSelect } from "@refinedev/antd";
import { DatePicker, Table, Select, Input, Row, Col, Form, DatePickerProps, Button } from "antd";
import { IFornitori, IProdotti } from 'interfaces';
import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useList, useOne, useUpdate } from "@refinedev/core";

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
export const OrdiniEdit: React.FC = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const inputRef = useRef(null);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [totale, setTotale] = useState(0);
    const { id } = useParams();
    const [idDocumento, setIdDocumento] = useState('');
    const { mutate } = useUpdate<any>();
    const { data, refetch } = useOne<any>({
        resource: "getRigheDocumento",
        id: idDocumento,
        queryOptions: {
            enabled: false
        }
    });

    const { selectProps: fornitoreSelectProps } = useSelect<IFornitori>({
        resource: "getAllSupplier",
        optionLabel: "name",
        optionValue: "id",

        pagination: {
            mode: "server"
        }
    });

    const { data: getListino } = useList<any>({
        resource: 'listinos',

        queryOptions: {
            enabled: true
        },

        pagination: {
            pageSize: 99999
        }
    });

    useEffect(() => {
        if (id) {
            setIdDocumento(id);
            getRighe(idDocumento);
        }
    }, [])

    const columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'Codice',
            dataIndex: 'externalId',
            key: 'externalId',
            // filters: [
            //     {
            //         text: 'Tabacchi',
            //         value: 'externalId',
            //     },
            // ],
            // onFilter: (value: any, record: any) => {
            //     console.log(record);
            //     return record && record.hasOwnProperty(value) && /^\d{2}/.test(record[value]);
            // },
            render: (value, record: any) => (
                value?.split('_')[0]
            )
        },
        {
            title: 'Barcode',
            dataIndex: 'barcode',
            key: 'barcode',
            render: (value, record: any) => (
                value
            )
        },
        {
            title: 'Prodotto',
            dataIndex: 'description',
            key: 'description',
            defaultSortOrder: 'ascend',
            sorter: (a: any, b: any) => {
                return (a.description.localeCompare(b.description));
            },

        },
        {
            title: 'Qta Venduta',
            dataIndex: 'quantita',
            key: 'quantita_vend',

            render: (value, record: any) => (
                <Form.Item
                    style={{ margin: 0 }}
                    name={'quantita'}
                    rules={[
                        {
                            required: true,
                            message: `Quantita is required.`,
                        },
                    ]}
                >
                    <Input ref={inputRef} placeholder="0" defaultValue={value} onKeyUp={() => handleSave(record, inputRef.current, dataSource)} />
                </Form.Item>
            )
        },
        {
            title: 'Giacenza',
            dataIndex: 'stockLevel',
            key: 'stockLevel',
            render: (value, record: any) => (
                value
            )
        },
        {
            title: 'Nr Ast/Conf',
            dataIndex: 'nrAstConf',
            key: 'nrAstConf',
            render: (value, record: any) => {
                return (value)
            }
        },
        {
            title: 'Min. Ordinabile Kg',
            dataIndex: 'minimoOrdinabile',
            key: 'minimoOrdinabile',
            render: (value, record: any) => (
                value
            )
        },
        {
            title: 'Qta da Ordinare Kg',
            dataIndex: 'qtaDaOrdinare',
            key: 'qtaDaOrdinare',
            render: (value, record: any) => {
                return (value)
            }
        },
        {
            title: 'Qta CIC',
            dataIndex: 'qtaCic',
            key: 'qtaCic',
            render: (value, record: any) => {
                return (value)
            }
        },
        {
            title: 'Costo Unitario',
            dataIndex: 'prices',
            key: 'prices',

            render: (value, record: any) => (
                Number(parseFloat(value[0].value)).toLocaleString('de', { minimumFractionDigits: 2 }) + ' €'
            )
        },
        {
            title: 'Importo',
            dataIndex: 'importo',
            key: 'importo',

            render: (value, record: any) => (
                Number(parseFloat(value)).toLocaleString('de', { minimumFractionDigits: 2 }) + ' €'
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',

            render: (value, record: any) => (
                <DeleteOutlined onClick={() => handleDelete(record)} />
            )
        },
    ];

    const getRighe = (id: string) => {
        console.log('Documento:', id);
        setTimeout(() => {
            refetch().then((res: any) => {
                // console.log(res);
                form.setFieldsValue({ 'fornitore': { label: res?.data?.data[0]?.supplier?.name, value: res?.data?.data[0]?.supplier?.id } })
                form.setFieldsValue({ 'numeroOrdine': (res?.data?.data[0].hasOwnProperty('order') ? res?.data?.data[0]?.order?.number : res?.data?.data[0]?.invoice?.number) })
                form.setFieldsValue({ 'data': dayjs(res?.data?.data[0]?.datetime) })
                setTotale(res?.data?.data[0]?.amount)
                const newData = [];
                for (let p of res?.data?.data[0]?.purchaseDocumentItems) {
                    console.log(p);
                    const itemListino = getListino?.data.find((x => x.codice === p.sku.externalId.split('_')[0]));
                    if (itemListino) {
                        const pz = itemListino.minimoOrdinabile * (Math.ceil(Math.round(Number(p.quantity) / (itemListino.minimoOrdinabile * 1000 / Number(itemListino.peso)))));
                        console.log(pz)
                        const tot = pz * itemListino.prezzoKg;
                        console.log(tot)
                        console.log(itemListino);
                        newData.push({
                            key: p.idItem,
                            externalId: p.sku.externalId.split('_')[0],
                            idSku: p.idSku,
                            idItem: p.idItem,
                            idTax: p.idTax,
                            idCategory: p.idCategory,
                            idDepartment: p.idDepartment,
                            description: p.sku.descriptionLabel,
                            quantita: Number(p.quantity),
                            peso: itemListino.peso,
                            prezzoKg: itemListino.prezzoKg,
                            tipologia: itemListino.tipologia,
                            nr: itemListino.nr,
                            nrAstConf: (Number(p.quantity) * (Number(itemListino.peso) / 1000) < itemListino.minimoOrdinabile ? 1 : Math.ceil(Math.round(Number(p.quantity) / (itemListino.minimoOrdinabile * 1000 / Number(itemListino.peso))))),
                            qtaCic: (Number(p.quantity) * (Number(itemListino.peso) / 1000) < itemListino.minimoOrdinabile ? Math.ceil(itemListino.minimoOrdinabile / (Number(itemListino.peso) / 1000)) : Number(Math.ceil(Math.round(Number(p.quantity) / (itemListino.minimoOrdinabile * 1000 / Number(itemListino.peso)))) * Math.ceil((itemListino.minimoOrdinabile * 1000) / Number(itemListino.peso))).toFixed(0)),
                            qtaDaOrdinare: (Number(p.quantity) * (Number(itemListino.peso) / 1000) < itemListino.minimoOrdinabile ? itemListino.minimoOrdinabile : Number(itemListino.minimoOrdinabile * (Math.ceil(Math.round(Number(p.quantity) / (itemListino.minimoOrdinabile * 1000 / Number(itemListino.peso)))))).toFixed(2)),
                            minimoOrdinabile: itemListino.minimoOrdinabile,
                            prices: [{
                                value: p.cost
                            }],
                            barcode: itemListino.barcode,
                            importo: Number(tot).toFixed(2)
                        })
                    }
                }
                setDataSource(newData)
            });
        }, 500)
    };

    const handleDelete = (key: any) => {
        // console.log(key);
        // console.log(dataSource);
        const newData = dataSource.filter(item => item.key !== key.key);
        setDataSource(newData);
        setTotale(sum(newData))
    };

    const sum = (d: any) => {
        return d.reduce((prev: any, curr: any, index: number, array: any) => (Number(prev) + Number(curr.importo)).toFixed(2), 0)
    }

    const handleSave = (row: any, value: any, data?: any) => {
        // console.log(data);

        const newData = [...data];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        // console.log(item)
        const minOrd = item.minimoOrdinabile ? item.minimoOrdinabile : Number(item.peso) / 1000;
        item.minimoOrdinabile = minOrd;
        item.stockLevel = item.hasOwnProperty('stocks') ? item.stocks?.quantity : 0
        item.quantita = value.input.value;
        item.importo = (Number(item.prices[0].value) * Number(value.input.value)).toFixed(2);
        item.nrAstConf = (Number(item.quantita) * (Number(item.peso) / 1000) < minOrd ? 1 : Math.ceil(Math.round(item.quantita) / (minOrd * 1000 / Number(item.quantity))));
        // console.log(Number(item.quantita) * (Number(item.peso)/1000), minOrd)
        item.qtaCic = (Number(item.quantita) * (Number(item.peso) / 1000) < minOrd ? Math.ceil(minOrd / (Number(item.peso) / 1000)) : Number(Math.ceil(Math.round(Number(item.quantita) / (minOrd * 1000 / Number(item.peso)))) * Math.ceil((minOrd * 1000) / Number(item.peso))).toFixed(0));
        item.qtaDaOrdinare = (Number(item.quantita) * (Number(item.peso) / 1000) < minOrd ? minOrd : Number(minOrd * (Math.ceil(Math.round(Number(item.quantita) / (minOrd * 1000 / Number(item.peso)))))).toFixed(2));
        const pz = minOrd * (Math.ceil(Math.round(Number(item.quantita) / (minOrd * 1000 / Number(item.peso)))));
        // console.log(pz)
        const tot = pz * item.prezzoKg;
        // console.log(tot)
        item.importo = Number(tot).toFixed(2);
        // newData.splice(index, 1, {
        //      ...item,
        //      ...row,
        // });
        setDataSource(newData);
        setTotale(sum(newData));
        setTimeout(() => {
            // console.log(row);
            // if (!row.hasOwnProperty('new') && !row.new) {
            //     for (let d of data?.data) {
            //         d.amount = sum(newData);
            //         d.purchaseDocumentItems[index].quantity = Number(value.input.value);
            //         d.purchaseDocumentItems[index].confirmedQuantity = Number(value.input.value);
            //     }
            // }

        }, 600)
    };

    const onFinish = (values: any) => {
        console.log('Received values of form: ', values);
    };

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    const addToCart = (p: number) => {
        const pro: any = queryResult.data?.data.find(x => x.id === p);
        // console.log(pro);
        const itemListino = getListino?.data.find((x => x.codice === pro.externalId.split('_')[0]));
        const pz = itemListino.minimoOrdinabile * (Math.ceil(Math.round(1 / (itemListino.minimoOrdinabile * 1000 / Number(itemListino.peso)))));
        const tot = pz * itemListino.prezzoKg;
        pro.key = pro.id;
        pro.idTax = pro.department.idTax;
        pro.idItem = pro.id;
        pro.idSku = pro.stocks.idProductVariant;
        pro.externalId = pro.externalId.split('_')[0];
        pro.peso = itemListino.peso;
        pro.prezzoKg = itemListino.prezzoKg;
        pro.tipologia = itemListino.tipologia;
        pro.nr = itemListino.nr;
        pro.quantita = 1;
        pro.nrAstConf = (pro.quantita * (Number(itemListino.peso) / 1000) < itemListino.minimoOrdinabile ? pro.quantita : Math.ceil(Math.round(1 / (itemListino.minimoOrdinabile * 1000 / Number(itemListino.peso)))));
        pro.qtaCic = (pro.quantita * (Number(itemListino.peso) / 1000) < itemListino.minimoOrdinabile ? Math.ceil(itemListino.minimoOrdinabile / (Number(itemListino.peso) / 1000)) : Number(Math.ceil(Math.round(1 / (itemListino.minimoOrdinabile * 1000 / Number(itemListino.peso)))) * Math.ceil((itemListino.minimoOrdinabile * 1000) / Number(itemListino.peso))).toFixed(0));
        pro.qtaDaOrdinare = (pro.quantita * (Number(itemListino.peso) / 1000) < itemListino.minimoOrdinabile ? itemListino.minimoOrdinabile : Number(itemListino.minimoOrdinabile * (Math.ceil(Math.round(pro.quantita / (itemListino.minimoOrdinabile * 1000 / Number(itemListino.peso)))))).toFixed(2));
        pro.minimoOrdinabile = itemListino.minimoOrdinabile;
        pro.importo = Math.ceil((itemListino.prezzoKg * itemListino.minimoOrdinabile) / 1);
        if (pro) pro.new = true;
        // @ts-ignore
        setDataSource([...dataSource, pro]);
        // console.log(dataSource);
    }

    const saveData = () => {
        delete data?.data[0].userFO;
        delete data?.data[0].salesPoint;
        if (data && data.data.length > 0) {
            data.data[0].purchaseDocumentItems = [];
            data.data[0].amount = parseFloat(totale.toString()).toFixed(2);
        }
        let index = 0;
        // console.log(dataSource);
        for (let p of dataSource) {
            // console.log(p);
            index++;
            data?.data[0].purchaseDocumentItems.push(
                {
                    "stockMovementEnabled": false,
                    "subtotal": false,
                    "refund": false,
                    "live": true,
                    "quantity": Number(p.quantita),
                    "confirmedQuantity": Number(p.quantita),
                    "idSku": p.idSku,
                    "idItem": p.idItem,
                    "idCategory": p.idCategory,
                    "idDepartment": p.idDepartment,
                    "cost": p.prices[0].value,
                    "idTax": p.idTax,
                    "rowNumber": index
                }
            )
        }
        // delete data?.data[0].purchaseDocumentItems;
        // data?.data[0].purchaseDocumentItems.push()
        // for (let p of data?.data[0].purchaseDocumentItems) {
        //     delete p.category;
        //     delete p.department;
        //     delete p.sku;
        //     delete p.tax;
        // }
        mutate({
            resource: "updateDocumento",
            id: idDocumento,
            values: data?.data[0],
        });
        setTimeout(() => {
            navigate("/documentis")
        }, 1000)
        // console.log(data?.data[0])
    }

    const { selectProps: prodottiSelectProps, queryResult } = useSelect<IProdotti>({
        resource: "getProdottis",

        onSearch: (value) => [
            {
                field: "description",
                operator: "containss",
                value: typeFilter === 'descrizione' ? value : '',
            },
            {
                field: "externalId",
                operator: "containss",
                value: typeFilter === 'ams' ? value : '',
            },
            {
                field: "barcode",
                operator: "containss",
                value: typeFilter === 'barcode' ? value : '',
            },
            {
                field: "stock",
                operator: "eq",
                value: true,
            },
        ],

        pagination: {
            mode: "server"
        }
    });

    const [typeFilter, setTypeFilter] = useState('descrizione');
    const onSelect = (e: any) => {
        console.log(e);
        setTypeFilter(e);
    }

    const options = queryResult.data?.data.map((item) => ({
        label: (item.externalId.includes('_') ? item.externalId.split('_')[0] : item.externalId) + ' - ' + item.description,
        value: item.id,
    }));

    return (
        <>
            <div>
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={28}>
                        <Col span={4} key={'fornitore'}>
                            <Form.Item
                                name={'fornitore'}
                                label={'Fornitore'}
                                rules={[
                                    {
                                        required: true,
                                        message: `Fornitore Richiesto!.`,
                                    },
                                ]}
                            >

                                <Select placeholder={'Seleziona Fornitore'} {...fornitoreSelectProps} />
                            </Form.Item>
                        </Col>
                        <Col span={5} key={'data'}>
                            <Form.Item
                                name={'data'}
                                label={'Data'}
                            >

                                <DatePicker defaultValue={dayjs()} format={'DD/MM/YYYY'} onChange={() => onChange} />
                            </Form.Item>
                        </Col>
                        <Col span={3} key={'numeroOrdine'}>
                            <Form.Item
                                name={'numeroOrdine'}
                                label={'Numero Ordine'}
                            >

                                <Input placeholder="N°" />
                            </Form.Item>
                        </Col>
                        <Col span={3} key={'ricerca'}>
                            <Select
                                onSelect={(e: any) => { onSelect(e) }}
                                placeholder={'Seleziona Filtro'}
                                style={{ width: '100%' }}>
                                <Select.Option key={'descrizione'}>
                                    Descrizione
                                </Select.Option>
                                <Select.Option key={'ams'}>
                                    Ams
                                </Select.Option>
                                <Select.Option key={'barcode'}>
                                    Barcode
                                </Select.Option>
                            </Select>

                        </Col>
                        <Col span={6} key={'prodotto'}>
                            <Form.Item
                                name={'prodotto'}
                                label={'Prodotto'}
                            >

                                <Select loading={true} placeholder={'Seleziona Prodotto'}
                                    allowClear={true}
                                    {...prodottiSelectProps}
                                    options={options}
                                    onSelect={(p: any) => addToCart(p)} />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button type="primary" onClick={() => { saveData() }}>Salva</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <Table
                    dataSource={dataSource}
                    rowKey="externalId"
                    columns={columns}
                    scroll={{ y: 650 }}
                    size="small"
                    sticky={false}
                    footer={() => (<>
                        <Row>
                            <Col span={20}>
                            </Col>
                            <Col span={4}>
                                <span>Totale: {Number(parseFloat(totale?.toString()).toFixed(2)).toLocaleString('de', { minimumFractionDigits: 2 })} €</span>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 15 }}>
                            <Col span={20}>

                            </Col>
                            <Col span={4}>
                                {/*<Button type="primary">Salva</Button>*/}
                            </Col>
                        </Row>
                    </>)}
                />
            </div>
            {/*<div>*/}
            {/*    <Row style={{marginTop: 15}}>*/}
            {/*        <Col span={22}>*/}
            {/*            <Button type="primary">Salva</Button>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</div>*/}
        </>
    );
};

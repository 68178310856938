import { useForm, Edit, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Switch, Row, Col, Button, message } from "antd";
import {IBarcode, IBarcodePackage, IListino, ISeller, IUser} from 'interfaces';
import React, {useEffect, useRef, useState} from 'react';
import {InputRef} from 'antd';
import { useCreate } from "@refinedev/core";

export const ListinoEdit: React.FC = () => {
    const inputRef = useRef<InputRef>(null);
    const { formProps, saveButtonProps, queryResult, formLoading } = useForm<IListino>({
        resource: "listinos",
        meta: {
            populate: '*'
        }
    });

    const barcodes: any = queryResult?.data?.data?.barcodes.map((item) => {
        return {value: item.id, label: item.code}
    })

    const barcode_packages: any = queryResult?.data?.data?.barcode_packages.map((item) => {
        return {value: item.id, label: item.code}
    })

    const { selectProps: barcodesSelectProps, queryResult: barCodeSelect } = useSelect<IBarcode>({
        resource: "barcodes",
        optionValue: "id",
        optionLabel: "code",

        pagination: {
            mode: "server"
        }
    });

    const { selectProps: barcode_packagesSelectProps, queryResult: barCodePackageSelect } = useSelect<IBarcodePackage>({
        resource: "barcode-packages",
        optionValue: "id",
        optionLabel: "code",

        pagination: {
            mode: "server"
        }
    });

    const newBarcode = useCreate<IBarcode>();
    const newBarcodePackage = useCreate<IBarcodePackage>();
    const addBarcode = () => {
        newBarcode.mutateAsync({
            resource: "barcodes",
            values: {
                code: inputRef.current?.input?.value,
                isPacket: true
            }
        }).then( (res: any) => {
            barcodes.push({value: res.data.data.id, label: res.data.data.attributes.code});
            setTimeout(() => {
                barCodeSelect.refetch();
                formProps.form?.setFieldsValue({'barcodes': barcodes});
                const b = barcodes.map( (a: any) => a.value);
                formProps.form?.setFieldsValue({'barcodes': b});
            }, 600)
        }).catch(() => {
            message.error('Barcode Esistente')
        })
    }

    const addBarcodePackage = () => {
        newBarcodePackage.mutateAsync({
            resource: "barcode-packages",
            values: {
                code: inputRef.current?.input?.value,
                isPackage: true
            }
        }).then( (res: any) => {
            barcode_packages.push({value: res.data.data.id, label: res.data.data.attributes.code});
            setTimeout(() => {
                barCodePackageSelect.refetch();
                formProps.form?.setFieldsValue({'barcode_packages': barcode_packages});
                const b = barcode_packages.map( (a: any) => a.value);
                formProps.form?.setFieldsValue({'barcode_packages': b});
            }, 600)
        }).catch(() => {
            message.error('Barcode Package Esistente')
        })
    }

    const handleChange = () => {
        // console.log(formProps.form?.getFieldValue('barcodes'))
    }

    const handleChangePackage = () => {
        // console.log(formProps.form?.getFieldValue('barcode-packages'))
    }

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label="Codice AMS"
                            name="codice"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Descrizione"
                            name="denominazione"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Categoria"
                            name="categoria"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={4}>
                        <Form.Item
                            label="Tipologia"
                            name="tipologia"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select a option"
                                allowClear
                            >
                                <Select.Option value="pz">pz</Select.Option>
                                <Select.Option value="gr">gr</Select.Option>
                                <Select.Option value="cf">cf</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Prezzo Kg"
                            name="prezzoKg"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Peso Gr"
                            name="peso"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Numero"
                            name="nr"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Prezzo Cf"
                            name="prezzoCf"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Min. Ordinabile Gr"
                            name="minimoOrdinabile"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Add Barcode"
                            name={"addBarcode"}
                        >
                            <Input.Group compact>
                                <Input ref={inputRef} style={{ width: 'calc(100% - 200px)' }} placeholder={'barcode'} />
                                <Button type="primary" onClick={() => addBarcode()}>Add</Button>
                            </Input.Group>
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            label="Barcodes"
                            name={"barcodes"}
                            valuePropName={"id"}
                        >
                            <Select
                                style={{ minWidth: 200 }}
                                mode="multiple"
                                placeholder="Select Barcodes"
                                defaultValue={barcodes}
                                onChange={handleChange}
                                {...barcodesSelectProps}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Add Barcode Package"
                            name={"addBarcodePackages"}
                        >
                            <Input.Group compact>
                                <Input ref={inputRef} style={{ width: 'calc(100% - 200px)' }} placeholder={'barcodePackage'} />
                                <Button type="primary" onClick={() => addBarcodePackage()}>Add</Button>
                            </Input.Group>
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            label="Barcode Package"
                            name={"barcode_packages"}
                            valuePropName={"id"}
                        >
                            <Select
                                style={{ minWidth: 200 }}
                                mode="multiple"
                                placeholder="Select Barcodes"
                                defaultValue={barcode_packages}
                                onChange={handleChangePackage}
                                {...barcode_packagesSelectProps}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};

import { useTable } from "@refinedev/antd";
import { Table, Space, Button, Tooltip } from "antd";
import type { ColumnsType } from 'antd/es/table';
import { IProdotti } from 'interfaces';
import { HttpError, useCreate, useDelete, useList, useNavigation } from "@refinedev/core";
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { EditOutlined, SolutionOutlined, DeleteOutlined, FileExcelOutlined } from '@ant-design/icons';
import tableExport from 'antd-table-export';
import moment from "moment";

export const DocumentiList: React.FC = (props) => {
    const navigate = useNavigate();
    const { list } = useNavigation();

    const [tipoDoc, setTipoDoc] = useState('ORDER');
    const { mutate } = useCreate<any>();
    const deleteDoc = useDelete<any>();

    const { tableProps } = useTable<any, HttpError, { description: string; category: IProdotti; createdAt: [Dayjs, Dayjs] }>({
        resource: "getDocumenti",

        filters: {
            permanent: [
                {
                    field: "type",
                    operator: "eq",
                    value: tipoDoc,
                },
            ]
        }
    });

    const { data: getListino } = useList<any>({
        resource: 'listinos',

        queryOptions: {
            enabled: true
        },

        pagination: {
            pageSize: 99999
        }
    });

    useEffect(() => {

    }, [tipoDoc])

    const changeType = (type: string) => {
        setTipoDoc(type);
    };

    const newOrder = (tipo: any) => {
        console.log('New Order');
        if (tipo === 'm') {
            navigate("/ordinis?m");
        } else {
            list('ordinis')
        }

    };

    const getRighe = (id: string) => {
        navigate("/ordinis/edit/" + id)
    };

    const generaFattura = (record: any) => {
        console.log(record);
        mutate({
            resource: "generaFatturaDaOrdine",
            values: {
                id: record.id,
                supplier: record.idSupplier,
                amount: record.amount,
            },
        });
        setTimeout(() => {
            setTipoDoc('INVOICE')
        }, 2000)
    };

    const generaFileExcel = (record: any) => {
        console.log(record);
        const prod = [];
        for (let p of record.purchaseDocumentItems) {
            const itemListino = getListino?.data.find((x => x.codice === p.sku.externalId.split('_')[0]));
            prod.push({
                externalId: p.sku.externalId.split('_')[0],
                qtaDaOrdinare: (Number(p.quantity) * (Number(itemListino.peso) / 1000) < itemListino.minimoOrdinabile ? itemListino.minimoOrdinabile : Number(itemListino.minimoOrdinabile * (Math.ceil(Math.round(Number(p.quantity) / (itemListino.minimoOrdinabile * 1000 / Number(itemListino.peso)))))).toFixed(2)),
                quantity: Number(((p.quantity / 10 >= 1) ? Math.ceil(Math.round(p.quantity / 10)) * 200 : 200) / 1000).toFixed(2)
            })
        }
        const exportInstance = new tableExport(prod, [
            {
                title: 'Codice AAMS',
                dataIndex: 'externalId',
            },
            {
                title: 'Quantita',
                dataIndex: 'qtaDaOrdinare',
            },
        ]);
        exportInstance.download("Ordine_" + dayjs(), 'xlsx')
    };

    const deleteDocumento = (record: any) => {
        console.log(record);
        deleteDoc.mutate({
            resource: "deleteDocumenti",
            id: record,
        });
        setTimeout(() => {
            window.location.reload();
        }, 2000)
    };

    const columns: ColumnsType<any> = [
        {
            title: 'Data e Ora',
            dataIndex: 'datetime',
            key: 'datetime',
            width: '10%',
            render: (value, record: any) => (
                moment(value).format('YYYY-MM-DD HH:s')
            )
        },
        {
            title: 'Numero',
            dataIndex: (tipoDoc === 'INVOICE' ? 'invoice' : 'order'),
            key: (tipoDoc === 'INVOICE' ? 'invoice' : 'order'),
            width: '5%',
            render: (value, record: any) => (
                value?.number
            )
        },
        {
            title: 'Fornitore',
            dataIndex: 'supplier',
            key: 'supplier',
            width: '40%',
            render: (value, record: any) => (
                value?.name
            )
        },
        {
            title: 'Ricevuto',
            dataIndex: 'order',
            key: 'order',
            width: '15%',
            render: (value, record: any) => {
                const p = (value ? value.status : 'PROCESSED');
                if (p === 'PROCESSED') {
                    console.log('DISABILITO')
                    // setIsDisabled(true);
                }
                return (
                    p
                )
            }
        },
        {
            title: 'Totale Imponibile',
            dataIndex: 'amount',
            key: 'amount',
            width: '20%',
            render: (value, record: any) => (
                Number(parseFloat(value).toFixed(2)).toLocaleString('de', { minimumFractionDigits: 2 }) + '€'
            )
        },
        {
            title: 'Azioni',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (value, record: any) => (
                <>
                    <Space>
                        <Tooltip placement="top" title={'Modifica'}>
                            <Button htmlType="button" type="primary" onClick={() =>
                                getRighe(value)} icon={<EditOutlined />}
                                style={{ backgroundColor: '#fdbe01', border: 'none' }}
                            />
                        </Tooltip>
                        <Tooltip placement="top" title={'Elimina'}>
                            <Button htmlType="button" type="primary" onClick={() =>
                                deleteDocumento(value)} icon={<DeleteOutlined />} danger
                            />
                        </Tooltip>
                        <Tooltip placement="top" title={'Genera Fattura'}>
                            <Button htmlType="button" type="primary" onClick={() =>
                                generaFattura(record)} hidden={tipoDoc === 'INVOICE' || ((tipoDoc === 'ORDER' && record.order.status !== 'NOT_PROCESSED'))}
                                icon={<SolutionOutlined />}
                            />
                        </Tooltip>
                        <Tooltip placement="top" title={'Genera File Excel'}>
                            <Button htmlType="button" type="primary"
                                style={{ backgroundColor: '#c700ff', border: 'none' }}
                                onClick={() =>
                                    generaFileExcel(record)} hidden={(tipoDoc === 'INVOICE')}
                                icon={<FileExcelOutlined />}
                            />
                        </Tooltip>
                    </Space>
                </>
            )
        }
    ];

    return (
        <>
            <div>
                <Space>
                    <Button htmlType="button" type="primary" onClick={() => newOrder('a')}>
                        Nuovo Ordine
                    </Button>
                    <Button htmlType="button" type="primary" style={{ backgroundColor: '#00a4ff', border: 'none' }} onClick={() => newOrder('m')} >
                        Ordine Manuale
                    </Button>
                    {/*<Button htmlType="button" type="primary" onClick={() => changeType('DDT')} style={{*/}
                    {/*    marginRight: 10*/}
                    {/*}}>*/}
                    {/*    DDT*/}
                    {/*</Button>*/}
                    <Button htmlType="button" type="primary" onClick={() => changeType('ORDER')}
                        style={{ backgroundColor: '#f1901e', border: 'none' }}>
                        Ordini
                    </Button>
                    <Button htmlType="button" type="primary" onClick={() => changeType('INVOICE')}
                        style={{ backgroundColor: '#f12884', border: 'none' }}>
                        Fatture
                    </Button>
                    <span style={{ marginLeft: 50, fontSize: 33, fontWeight: 600 }}>
                        {tipoDoc === 'ORDER' ? 'Lista Ordini' : 'Lista Fatture'}
                    </span>
                </Space>
                <Table {...tableProps}
                    sticky={true}
                    style={{
                        marginTop: 10
                    }}
                    pagination={false}
                    rowKey="id" columns={columns} size="small" />
            </div>
        </>
    );
};

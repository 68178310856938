import {
    List,
    TextField,
    TagField,
    DateField,
    useTable,
    EditButton,
    DeleteButton,
    useSelect,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Table, Space, Card, Form, FormProps, Input, Select, Button, Popover } from "antd";
// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { IListino, IListinoFilterVariables, ISeller } from 'interfaces';
import React, { useState } from 'react';
import { CrudFilters, HttpError } from "@refinedev/core";

export const ListinoList: React.FC = () => {
    const [visible, setVisible] = useState(false);
    const { tableProps, searchFormProps } = useTable<IListino, HttpError,
        IListinoFilterVariables>({
            resource: "listinos",
            onSearch: (params) => {
                const filters: CrudFilters = [];
                const { denominazione } = params;

                filters.push(
                    {
                        field: "denominazione",
                        operator: "containss",
                        value: denominazione,
                    }
                );

                return filters;
            }
        });

    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };
    return (
        <List title={''}>
            <Popover
                placement="leftTop"
                style={{
                    float: "right"
                }}
                content={<Filter formProps={searchFormProps} />}
                title="Custom Filter"
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisibleChange}
            >
                <Button type="primary" style={{ marginBottom: 15 }}>Filter</Button>
            </Popover>
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="codice" title="codice" />
                <Table.Column dataIndex="denominazione" title="denominazione" />
                <Table.Column dataIndex="categoria" title="categoria" />
                <Table.Column dataIndex="prezzoCf" title="prezzo" />
                <Table.Column dataIndex="prezzoKg" title="prezzoKg" />
                <Table.Column dataIndex="minimoOrdinabile" title="minimoOrd" />
                <Table.Column<IListino>
                    title="Actions"
                    dataIndex="actions"
                    render={(_text, record): React.ReactNode => {
                        return (
                            <Space>
                                <EditButton
                                    size="small"
                                    resource="listinos"
                                    recordItemId={record.id}
                                    hideText
                                />
                                <DeleteButton
                                    size="small"
                                    resource="listinos"
                                    recordItemId={record.id}
                                    hideText
                                />
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {

    return (
        <Form layout="vertical" {...formProps}>
            <Form.Item label="Search" name="denominazione">
                <Input
                    placeholder="descrizione..."
                    prefix={<SearchOutlined />}
                />
            </Form.Item>
        </Form>
    );
};

import { Create, useForm, useSelect } from "@refinedev/antd";
import { Col, DatePicker, Form, Input, Row, Select, Switch } from "antd";

import {ILicenzeRilasciate, ITipiLicenze, IUser} from 'interfaces';
import React, {useContext} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import { useCreate } from "@refinedev/core";
import dayjs from 'dayjs';

export const UserCreate = () => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const [user, setUser] = value2;
    const { formProps, saveButtonProps, onFinish } = useForm<IUser>({
        meta: {
            populate: '*'
        }
    });

    const createLicenza = useCreate<ILicenzeRilasciate>()
    const handleSubmit = (e: any) => {
        console.log(e);
        const d = new Date();
        e.username = e.email;
        e.role = 1;
        e.gruppo = 2;
        e.rivenditori = user.rivenditori.id;
        onFinish(e).then((res: any) => {
                console.log(res);
                createLicenza.mutateAsync({
                    resource: 'licenze-rilasciates',
                    values: {
                        utenteIntestatario: e.nome + ' ' + e.cognome,
                        emessaDa: user.rivenditori.ragioneSociale,
                        gestitaDa: user.rivenditori.ragioneSociale,
                        rifTipoLicenza: e.rifTipoLicenza,
                        rilasciataIl: d,
                        validaDal: e.validaDal,
                        validaAl: new Date(d.getFullYear(), d.getMonth(), d.getDate() + 365),
                        isLicenzaAttiva: e.isLicenzaAttiva,
                        rifUtenteEmessa: res.data.id,
                        rifUtenteGestisce: res.data.id,
                        rifUtenteAssegnato: res.data.id
                    }
                }).then( resL => {
                    console.log(resL);
                })
            }
        );
        // form.setFieldsValue({ name: 'username', value: e.target.value})
    }

    const { selectProps: licenzeSelectProps } = useSelect<ITipiLicenze>({
        resource: "tipi-licenzes",
        optionValue: 'id',
        optionLabel: 'descLicenza',

        pagination: {
            mode: "server"
        }
    });

    const validatePassword = (rule: any, value: any, callback: any) => {
        if (value && value !== "Secret") {
            callback("Error!");
        } else {
            callback();
        }
    };

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical" onFinish={(values) => handleSubmit(values)}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label="Nome"
                            name="nome"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Cognome"
                            name="cognome"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Telefono"
                            name="telefono"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            hidden={false}
                            label="Username"
                            name="username"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Password"
                            name="password"
                            hasFeedback
                            rules={[{ required: true, message: 'Please add a password' },
                                { min: 8, message: 'Password must have a minimum length of 8' },
                                {
                                    pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
                                    message: 'Password must contain at least one lowercase letter, uppercase letter, number, and special character'
                                }]}
                        >
                            <Input type={"password"}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Confirmed"
                            name="confirmed"
                            valuePropName="checked"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            hidden={false}
                            label="Tipo Licenza"
                            name="rifTipoLicenza"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...licenzeSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Attivato"
                            name="isLicenzaAttiva"
                            valuePropName="checked"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Attiva dal..."
                            name="validaDal"
                            getValueProps={(value) => ({
                                value: value ? dayjs(value) : '',
                            })}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker style={{width: '100%'}} format={'DD-MM-YYYY'}/>
                        </Form.Item>
                    </Col>
                </Row>





                {/*<Form.Item*/}
                {/*    label="Riferimento Gruppo"*/}
                {/*    name={["gruppo", "id"]}*/}
                {/*    rules={[*/}
                {/*        {*/}
                {/*            required: true,*/}
                {/*            message: 'Seleziona un Gruppo!'*/}
                {/*        },*/}
                {/*    ]}*/}
                {/*>*/}
                {/*    <Select {...gruppoSelectProps} />*/}
                {/*</Form.Item>*/}

            </Form>
        </Create>
    );
};

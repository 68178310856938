type TitleProps = {
    collapsed: boolean;
}

export default function Title({ collapsed }: TitleProps) {
    if (collapsed)
        return <img
            src="/images/logoTb.png"
            alt="Refine"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '80px',
                padding: '8px 16px',
            }}
        />

    return <img
        src="/images/logoTb.png"
        alt="Refine"
        style={{
            width: '150px',
            // padding: '12px 24px',
            padding: '35px',
        }}
    />

}
import { useForm, Edit, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Switch, Row, Col, DatePicker } from "antd";
import { ITipiLicenze, IUser } from 'interfaces';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export const UserEdit: React.FC = () => {
    const { formProps, saveButtonProps, queryResult, formLoading } = useForm<IUser>({
        meta: {
            populate: "gruppo, rivenditori, rifLicenzaEmessa, rifLicenzaEmessa.rifTipoLicenza",
        },
    });

    const [requirePassword, setRequirePassword] = useState(false);


    useEffect(() => {
    }, [formLoading])

    const onChange = (e: any) => {
        if (e) {
            setRequirePassword(true);
        } else {
            setRequirePassword(false);
        }
    };

    console.log(queryResult);
    const { selectProps: licenzeSelectProps } = useSelect<ITipiLicenze>({
        resource: "tipi-licenzes",
        optionValue: 'id',
        optionLabel: 'descLicenza',
        defaultValue: queryResult?.data?.data?.rifLicenzaEmessa[0]?.rifTipoLicenza?.id,

        pagination: {
            mode: "server"
        }
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            label="Nome"
                            name="nome"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Cognome"
                            name="cognome"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Telefono"
                            name="telefono"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            hidden={false}
                            label="Username"
                            name="username"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cambia Password"
                            name={"cp"}
                        >
                            <Switch onChange={(e: any) => onChange(e)} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item hidden={!requirePassword}
                            label="Password"
                            name="password"
                            rules={[{ required: requirePassword, message: 'Please add a password' },
                            { min: 8, message: 'Password must have a minimum length of 8' },
                            {
                                pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
                                message: 'Password must contain at least one lowercase letter, uppercase letter, number, and special character'
                            }]}
                        >
                            <Input type={"password"} />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            label="Confirmed"
                            name="confirmed"
                            valuePropName="checked"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            hidden={false}
                            label="Tipo Licenza"
                            name={['rifLicenzaEmessa', '0', 'rifTipoLicenza', 'id']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select {...licenzeSelectProps} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Attivato"
                            name={['rifLicenzaEmessa', '0', 'isLicenzaAttiva']}
                            valuePropName="checked"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Attiva dal..."
                            name={['rifLicenzaEmessa', '0', 'validaDal']}
                            getValueProps={(value) => {
                                return ({
                                    value: value ? dayjs(value) : '',
                                })
                            }}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <DatePicker style={{ width: '100%' }} format={'DD-MM-YYYY'} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};

import { DateField } from "@refinedev/antd";

import {
    Button,
    notification,
    Popover,
    Space,
    Table,
    DatePicker,
    Row,
    Col,
    Typography,
} from "antd";

import { Progress } from 'antd';
import { useApiUrl } from "@refinedev/core";
import axios from 'axios';
import {useEffect, useState} from 'react';
import {TOKEN_KEY} from '../../constants';
import type { ColumnsType } from 'antd/es/table';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import {apiFunction} from '../../apiFuntion';
const {RangePicker} = DatePicker;
const {Title} = Typography;

interface DataType {
    key: string;
    descrizione: string;
    categoria: string;
    qta: number;
    totale: number;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend
);

export const DashboardList: React.FC = () => {
    const apiUrl = useApiUrl();
    const [isLoadingRiferimento, setIsLoadingRiferimento] = useState(false);
    const [isLoadingListino, setIsLoadingListino] = useState(false);
    const [isLoadingCassa, setIsLoadingCassa] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [showBar, setShowBar] = useState(false);
    const [value, setValue] = useState(0);
    const [data, setData] = useState<DataType[]>([]);
    const [visible, setVisible] = useState(false);
    const [fromData, setFromData] = useState(new Date());
    const [toData, setToData] = useState(new Date());
    const [lis, setLis] = useState('');

    function getData() {
            setIsLoadingRiferimento(true);
            setShowBar(true);
        axios.get(apiUrl + '/updateListinoRiferimento', {
            headers: {"Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY)}}).then((res: any) => {
            if (res && res.status === 200) {
                const i = setInterval(() => {
                    axios.get(apiUrl + '/jobs/' + res.data.id, {headers: {"Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY)}}).then((res: any) => {
                        if (res && res.data.data.attributes.isCompleted) {
                            setIsLoadingRiferimento(false);
                            clearInterval(i);
                            setValue(res.data.data.attributes.progressivo);
                        } else {
                            setValue(res.data.data.attributes.progressivo);
                        }
                    });
                }, 1000)
            }
        }).catch((err: any) => {
            console.log(err)
        }).finally(() => {
        });
    }

    function getDataListino() {
        setIsLoadingListino(true);
        setShowBar(true);
        axios.get(apiUrl + '/updateListino', {headers: {"Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY)}}).then((res: any) => {
            if (res && res.status === 200) {
                const i = setInterval(() => {
                    axios.get(apiUrl + '/jobs/' + res.data.id, {headers: {"Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY)}}).then((res: any) => {
                        if (res && res.data.data.attributes.isCompleted) {
                            setIsLoadingListino(false);
                            clearInterval(i);
                            setValue(res.data.data.attributes.progressivo);
                        } else {
                            setValue(res.data.data.attributes.progressivo);
                        }
                    });
                }, 1000)
            }
        }).catch((err: any) => {
            console.log(err)
        }).finally(() => {
        });
    }

    function updateToCassa() {
        setIsLoadingCassa(true);
        setShowBar(true);
        axios.get(apiUrl + '/syncProdottiToCassa', {headers: {"Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY)}}).then((res: any) => {
            if (res && res.status === 200) {
                const i = setInterval(() => {
                    axios.get(apiUrl + '/jobs/' + res.data.id, {headers: {"Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY)}}).then((res: any) => {
                        if (res && res.data.data.attributes.isCompleted) {
                            setIsLoadingCassa(false);
                            clearInterval(i);
                            setValue(res.data.data.attributes.progressivo);
                            notification.open({
                                message: 'Notification Title',
                                description:
                                    'Aggiornamento Effettuato con Successo.',
                                onClick: () => {
                                    console.log('Notification Clicked!');
                                },
                            });
                            setTimeout(() => {
                                setShowBar(false);
                            }, 2000)
                        } else if (res.data.data.attributes.progressivo === 100 && !res.data.data.attributes.isCompleted) {
                            clearInterval(i);
                            setIsLoadingCassa(false);
                            notification.open({
                                message: 'Notification Title',
                                description:
                                    'Si è verificato un Problema...',
                                onClick: () => {
                                    console.log('Notification Clicked!');
                                },
                            });
                            setTimeout(() => {
                                setShowBar(false);
                            }, 2000)
                        } else {
                            setValue(res.data.data.attributes.progressivo);
                        }
                    });
                }, 1000)
            }
        }).catch((err: any) => {
            console.log(err)
        }).finally(() => {
        });
    }

    function importListino() {
        setIsLoadingCassa(true);
        setShowBar(true);
        axios.post(apiUrl + '/import', {}, {headers: {"Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY)}}).then((res: any) => {
            if (res && res.status === 200) {
                const i = setInterval(() => {
                    axios.post(apiUrl + '/getStatusImport', {
                        idImport: res.data.idImport
                    }, {headers: {"Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY)}}).then((res: any) => {
                        if (res && res.data.status === 'COMPLETED') {
                            setIsLoadingCassa(false);
                            clearInterval(i);
                            setValue(res.data.progress);
                        } else {
                            setValue(res.data.progress);
                        }
                    });
                }, 3000)
            } else {
                setIsLoadingCassa(false);
                setShowBar(false);
                notification.open({
                    message: 'Notification Title',
                    description:
                        'File Listino non Presente.',
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });
            }

        }).catch((err: any) => {
            console.log(err)
        }).finally(() => {
        });
    }

    function getSoldByItems() {
        setIsLoading(true);
        setShowBar(false);
        const result: DataType[] = [];
        axios.post(apiUrl + '/getSoldByItems', {
            fromData: fromData,
            toData: toData
        }, {headers: {"Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY)}}).then((res: any) => {
            console.log(res.status, res.data)
            if (res && res.status === 200 && res.data.data.totalCount > 0) {
                // console.log(res)
                for (const r of res.data.data.records) {
                    result.push({
                        key: r.idItem,
                        descrizione: r.item.description,
                        categoria: r.item.category.description,
                        qta: r.quantity,
                        totale: r.profit
                    })
                }
                // console.log(result)
                setIsLoading(false);
                setData(result);
                if (result.length > 0) {
                    setShowTable(true);
                    handleVisibleChange(false)
                }
            } else {
                setIsLoading(false);
                notification.open({
                    message: 'Notification Title',
                    description:
                        'Nessun Dato Presente.',
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });
            }
        }).catch((err: any) => {
            console.log(err)
        }).finally(() => {
        });
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Descrizione',
            dataIndex: 'descrizione',
        },
        {
            title: 'Categoria',
            dataIndex: 'categoria',
        },
        {
            title: 'Qta',
            dataIndex: 'qta',
        },
        {
            title: 'Totale',
            dataIndex: 'totale',
            render: (value, record) => (
                record.totale + '€'
            )
        },
    ];

    useEffect(() => {
        // console.log(data);
    })

    const hide = () => {
        setVisible(false);
    };

    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    const onChange = (e: any) => {
        // console.log(e);
        setFromData(new Date(e[0].$d));
        setToData(new Date(e[1].$d));
        // console.log(e.$d);
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Statistica Vendita Tabacco',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const optionsO = {
        plugins: {
            title: {
                display: true,
                text: 'Statistica Ordini Tabacco',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const labels = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio'];

    const dataC = {
        labels,
        datasets: [
            {
                label: 'Sigarette',
                data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                backgroundColor: 'rgb(255, 99, 132)',
            },
            {
                label: 'Trinciati',
                data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                backgroundColor: 'rgb(75, 192, 192)',
            },
            {
                label: 'Sigari',
                data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                backgroundColor: 'rgb(53, 162, 235)',
            },
        ],
    };

    const dataO = {
        labels,
        datasets: [
            {
                label: 'Sigarette',
                data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                backgroundColor: 'rgb(255, 99, 132)',
            },
            {
                label: 'Trinciati',
                data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                backgroundColor: 'rgb(75, 192, 192)',
            },
            {
                label: 'Sigari',
                data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                backgroundColor: 'rgb(53, 162, 235)',
            },
        ],
    };

    apiFunction.getListino().then( (res: any) => {
        setLis(res);
    })

    return (
        <>
            <Title level={3} >Ultimo Aggiornamento Listino: <DateField style={{fontSize: 28}} value={lis} format={'DD-MM-YYYY'} /></Title>
            <Button hidden={true} type="primary" onClick={() => getData()} style={{marginRight: 10}} loading={isLoadingRiferimento}>1-Update Listino Riferimento</Button>
            <Button hidden={true} type="primary" onClick={() => getDataListino()} style={{marginRight: 10}} loading={isLoadingListino}>2-Update Listino</Button>
            <Button hidden={false} type="primary" onClick={() => updateToCassa()} style={{marginRight: 10}} loading={isLoadingCassa}>Update Listino {'>>'} Cassa</Button>
            <Popover
                content={
                    <>
                        <Space direction="vertical">
                            <RangePicker onChange={(e) => onChange(e)}/>
                            <a onClick={() => getSoldByItems()}>Conferma</a></Space>
                    </>
                }
                title="Get Vendite"
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisibleChange}
            >
                <Button type="primary" style={{marginRight: 10}} loading={isLoading}>Statistiche Venduto</Button>
            </Popover>
            <Button hidden={true} type="primary" danger onClick={() => importListino()} style={{marginRight: 10}} loading={isLoadingCassa}>Import Listino Su Cassa in Cloud</Button>
            <div hidden={!showBar} style={{ marginTop: 30}}>
                <Progress
                    strokeColor={{
                        from: '#108ee9',
                        to: '#87d068',
                    }}
                    percent={((isLoadingCassa || isLoadingListino || isLoadingRiferimento) ? value : 99)}
                    status={((isLoadingCassa || isLoadingListino || isLoadingRiferimento) ? 'active' : 'success')}
                />
            </div>
            <div hidden={!showTable} style={{
                marginTop: 30
            }}>
                <Table columns={columns} dataSource={data} size={'small'}/>
            </div>
            <hr />
            <Row justify={'center'}>
                <Col span={12}>
                    <div style={{
                        width: '80%',
                        height: 600
                    }}>
                        <Bar options={options} data={dataC} />
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{
                        width: '80%',
                        height: 600
                    }}>
                        <Bar options={optionsO} data={dataO} />
                    </div>
                </Col>
            </Row>
    </>
    );
};

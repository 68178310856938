import { Button, Form, Input, Select, notification, Switch } from "antd";
import { useApiUrl, useCreate, useCustom, useGetIdentity, useList, useUpdate } from "@refinedev/core";
import { useEffect, useState } from 'react';
import { IParametri } from '../../interfaces';

export const ParametriList: React.FC = () => {
    const apiUrl = useApiUrl();
    const [form] = Form.useForm();
    const { data: identity } = useGetIdentity<{ id: number; fullName: string }>();
    const [id, setId] = useState(0);
    const [tokenCassa, setTokenCassa] = useState('');
    const [idSalesPoint, setIdSalesPoint] = useState([]);
    const [idSalesPointFromCassa, setIdSalesPointFromCassa] = useState([]);
    const [idDepartment, setIdDepartment] = useState([]);
    const [idDepartmentFromCassa, setIdDepartmentFromCassa] = useState([]);
    const [idCategorie, setIdCategorie] = useState([]);
    const [idCategorieFromCassa, setIdCategorieFromCassa] = useState([]);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState(false);
    const [password, setPassword] = useState(false);
    const [isGiorni, setIsGiorni] = useState(false);
    const { mutate } = useUpdate<IParametri>();
    const createParametri = useCreate<IParametri>();
    const data = useList<IParametri>({
        resource: 'parametris',
        queryOptions: {
            enabled: false
        },

        filters: [
            {
                field: 'user][id',
                value: identity?.id,
                operator: 'eq'
            }
        ]
    })

    const getTokenFromCassa = useCustom<any>({
        url: `${apiUrl}/getToken/${identity?.id}`,
        method: "get",
        queryOptions: {
            enabled: false
        }
    })

    const getCookieFromCassa = useCustom<any>({
        url: `${apiUrl}/getCookie`,
        method: "post",
        queryOptions: {
            enabled: false
        },
        config: {
            payload: {
                username,
                password
            }
        }
    })

    const getSalesPointFromCassa = useCustom<any>({
        url: `${apiUrl}/getSalesPoint`,
        method: "post",
        queryOptions: {
            enabled: false
        },
        config: {
            payload: {
                token: tokenCassa
            }
        }
    })

    const getDepartmentFromCassa = useCustom<any>({
        url: `${apiUrl}/getDepartment`,
        method: "post",
        queryOptions: {
            enabled: false
        },
        config: {
            payload: {
                token: tokenCassa
            }
        }
    })

    const getCategorieFromCassa = useCustom<any>({
        url: `${apiUrl}/getCategories`,
        method: "post",
        queryOptions: {
            enabled: false
        },
        config: {
            payload: {
                token: tokenCassa
            }
        }
    })

    function getPar() {
        data.refetch().then((p: any) => {
            if (p && p.data.data && p.data.data.length > 0) {
                console.log('ID', p)
                setId(p.data.data[0].id)
                setIdSalesPoint((p.data && p.data.data && p.data.data.length > 0 ? p.data?.data[0].idSalesPoint : []));
                setIdCategorie((p.data && p.data.data && p.data.data.length > 0 ? p.data?.data[0].idCategorie : []));
                setIdDepartment((p.data && p.data.data && p.data.data.length > 0 ? p.data?.data[0].idDepartment : []));
                setUsername(p.data?.data[0].username);
                setPassword(p.data?.data[0].password);
                // form.setFieldsValue({
                //     apiKey: p.data?.data[0].apiKey,
                //     cookie: p.data?.data[0].cookie,
                //     idSalesPoint: p.data?.data[0].idSalesPoint,
                //     idDepartment: p.data?.data[0].idDepartment,
                //     username: p.data?.data[0].username,
                //     password: p.data?.data[0].password,
                //     tagKey: p.data?.data[0].tagKey,
                //     tagDominio: p.data?.data[0].tagDominio,
                //     tagStore: p.data?.data[0].tagStore,
                //     idCategorie: (p.data?.data[0]?.idCategorie ? p.data?.data[0]?.idCategorie : [])
                // });
                form.setFieldsValue(p.data.data[0])
            } else {
                console.log('NOID')
                setId(0);
            }
        });
    }
    useEffect(() => {
        if (identity?.id) {
            getPar();
            setId(identity?.id)
            // data.refetch().then( (res: any) => {
            //     console.log(res.data);
            //     form.setFieldsValue(res.data.data[0])
            // })
        }
    }, [identity?.id])

    // useEffect(() => {
    //     // setId(Number(createParametri.data?.data.id))
    //     // setTimeout(() => {
    //     //     console.log(createParametri.data?.data.id);
    //     // }, 1000)

    // }, [createParametri.isSuccess])

    const giorni = (e: any) => {
        console.log(e);
        if (e === 'venduto') {
            setIsGiorni(true);
        } else {
            setIsGiorni(false);
        }
    }

    const onFinish = (values: any) => {
        console.log(values, id);
        console.log(idCategorieFromCassa);
        console.log(idSalesPointFromCassa);
        console.log(idDepartmentFromCassa);
        console.log(form.getFieldValue('idCategorie'));
        console.log(form.getFieldValue('idSalesPoint'));
        console.log(form.getFieldValue('idDepartment'));
        if (id) {
            let ct = [];
            let st = [];
            let dt = [];
            if (idCategorieFromCassa && idCategorieFromCassa.length > 0
                && idDepartmentFromCassa && idDepartmentFromCassa.length > 0
                && idSalesPointFromCassa && idSalesPointFromCassa.length > 0) {
                for (const c of form.getFieldValue('idCategorie')) {
                    ct.push(idCategorieFromCassa.find((x: any) => x.value === c))
                }
                st.push(idSalesPointFromCassa.find((x: any) => x.value === form.getFieldValue('idSalesPoint')));
                dt.push(idDepartmentFromCassa.find((x: any) => x.value === form.getFieldValue('idDepartment')));
            } else {
                ct = form.getFieldValue('idCategorie');
                st = form.getFieldValue('idSalesPoint');
                dt = form.getFieldValue('idDepartment');
            }
            values.idSalesPoint = st;
            values.idCategorie = ct;
            values.idDepartment = dt;
            values.isAnagraficaUnica = form.getFieldValue('isAnagraficaUnica');
            values.isSalesPointUnico = form.getFieldValue('isSalesPointUnico');
            values.isAutoUpdate = form.getFieldValue('isAutoUpdate');
            mutate({
                id: Number(id),
                resource: "parametris",
                values: values
            })
        } else {
            console.log(values);
            values.user = identity?.id;
            values.descrizione = 'Cassa in Cloud';
            createParametri.mutateAsync({
                resource: "parametris",
                values: values
            }).then(() => {
                window.location.reload();
            })

        }
    };

    function onUpdate() {
        setLoading(true);
        setIdCategorie([]);
        setIdDepartment([]);
        setIdSalesPoint([]);
        if (form.getFieldValue('apiKey')) {
            getCookieFromCassa.refetch().then((c: any) => {
                if (c.status === 'success' && c.data.data) {
                    console.log(c.data.data)
                    form.setFieldsValue({
                        cookie: c.data.data.data.cookie.split(';')[0]
                    });
                    form.setFieldsValue({
                        userFO: c.data.data.data.id
                    })
                    setTimeout(() => {
                        getTokenFromCassa.refetch().then((t: any) => {
                            if (t.status === 'success' && t.data.data.token) {
                                console.log(t.data.data.token);
                                setTokenCassa(t.data.data.token);
                                setTimeout(() => {
                                    getSalesPointFromCassa.refetch().then((s: any) => {
                                        console.log(s);
                                        if (t.status === 'success') {
                                            console.log(s.data.data.idSalesPoint)
                                            let dt = [];
                                            for (const d of s.data.data.idSalesPoint) {
                                                dt.push({ value: d.id, label: d.descrizione })
                                            }
                                            // @ts-ignore
                                            setIdSalesPointFromCassa(dt);
                                            setTimeout(() => {
                                                getDepartmentFromCassa.refetch().then((d: any) => {
                                                    console.log(d);
                                                    if (t.status === 'success') {
                                                        console.log(d.data.data.idDepartment);
                                                        let dt = [];
                                                        for (const dd of d.data.data.idDepartment) {
                                                            dt.push({ value: dd.id, label: dd.descrizione })
                                                        }
                                                        // @ts-ignore
                                                        setIdDepartmentFromCassa(dt);
                                                        setTimeout(() => {
                                                            getCategorieFromCassa.refetch().then((c: any) => {
                                                                console.log(c);
                                                                if (t.status === 'success') {
                                                                    console.log(c.data.data.idCategorie);
                                                                    let ct = [];
                                                                    for (const td of c.data.data.idCategorie) {
                                                                        ct.push({ value: td.id, label: td.descrizione })
                                                                    }
                                                                    // @ts-ignore
                                                                    setIdCategorieFromCassa(ct);
                                                                    setLoading(false);
                                                                }
                                                            })
                                                        }, 1000)
                                                    }
                                                })
                                            }, 1000)
                                        }
                                    })
                                }, 1000)
                            }
                        });
                    }, 1000);
                }
            })
        } else {
            notification.open({
                message: 'Attenzione',
                description:
                    'Devi inserire la Chiave APi di Cassa in Cloud',
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
        }
        console.log('Get Data From Cassa in Cloud: ');
    }

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item name="username" label="User Cassa in Cloud">
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="password" label="Password Cassa in Cloud">
                    <Input type={'password'} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="apiKey" label="ApiKey from Cassa in Cloud">
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    name="ordineAutomatico"
                    label="Ordine Automatico"
                    hasFeedback
                    rules={[{ required: true, message: 'Seleziona una Tipologia!' }]}
                >
                    <Select
                        defaultValue=""
                        options={[
                            {
                                value: '',
                                label: 'Seleziona...',
                            },
                            {
                                value: 'venduto',
                                label: 'In Base al Venduto',
                            },
                            {
                                value: 'giacenza',
                                label: 'In Base alla Giacenza Minima',
                            },
                        ]}
                        onChange={(e: any) => giorni(e)}
                    />
                </Form.Item>
                <Form.Item name="nGiorni" label="Numero di Giorni" hidden={!isGiorni}>
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <Button hidden={id > 0} type="primary" htmlType="submit">
                    Salva Impostazioni
                </Button>
                <Form.Item>
                    <Button type="primary" htmlType="button" hidden={id === 0} onClick={() => {
                        onUpdate();
                    }} loading={loading}>
                        Get Dati From Cassa In Cloud
                    </Button>
                </Form.Item>
                <Form.Item name="isSalesPointUnico" label="Token Unico" valuePropName={'checked'}>
                    <Switch />
                </Form.Item>
                <Form.Item name="isAnagraficaUnica" label="Anagrafica Unificata" valuePropName={'checked'}>
                    <Switch />
                </Form.Item>
                <Form.Item name="isAutoUpdate" label="Update Automatico Listino" valuePropName={'checked'}>
                    <Switch />
                </Form.Item>
                <Form.Item name="cookie" label="Cookie from Cassa in Cloud">
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="userFO" label="UserFO from Cassa in Cloud">
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item hidden={true} name="tagKey" label="Api Key for Etichette">
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item hidden={true} name="tagDominio" label="Server Etichette">
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item hidden={true} name="tagStore" label="Store Id Etichette">
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    name="idSalesPoint"
                    label="Id Sales Point"
                    hasFeedback
                    rules={[{ required: false, message: 'Please select Sales Id Point!' }]}
                >
                    <Select
                        key={Math.random()}
                        value={idSalesPoint}
                        options={idSalesPoint && idSalesPoint.length > 0 ? idSalesPoint : idSalesPointFromCassa && idSalesPointFromCassa.length > 0 ? idSalesPointFromCassa : []}
                        allowClear={true}
                    />
                </Form.Item>
                <Form.Item
                    name="idDepartment"
                    label="Id Department"
                    hasFeedback
                    rules={[{ required: false, message: 'Please select Sales Id Department!' }]}
                >
                    <Select
                        key={Math.random()}
                        value={idDepartment}
                        options={idDepartment && idDepartment.length > 0 ? idDepartment : idDepartmentFromCassa && idDepartmentFromCassa.length > 0 ? idDepartmentFromCassa : []}
                        allowClear={true}
                    />
                </Form.Item>
                <Form.Item
                    name="idCategorie"
                    label="Categorie"
                    hasFeedback
                    rules={[{ required: false, message: 'Please select category!' }]}
                >
                    <Select
                        key={Math.random()}
                        value={idCategorie}
                        mode="multiple"
                        options={idCategorie && idCategorie.length > 0 ? idCategorie : idCategorieFromCassa && idCategorieFromCassa.length > 0 ? idCategorieFromCassa : []}
                        allowClear={true}
                    />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                    Salva Impostazioni
                </Button>
            </Form>
        </>

    );
};

import { useTable } from "@refinedev/antd";

import {
    Table,
    Input,
    Space,
    Button,
    Row,
    Col,
    Form,
    Popover,
    notification,
    Tag,
    Modal,
    DatePickerProps,
    Tooltip,
} from "antd";

import type { InputRef } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {IProdotti} from 'interfaces';
import { HttpError, useCreate, useDelete, useUpdate } from "@refinedev/core";
import React, {useEffect, useState} from 'react';
import {DeleteOutlined, EditOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import { Dayjs } from "dayjs";

export const FornitoriList: React.FC = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [form] = Form.useForm();

    const { tableProps, searchFormProps, tableQueryResult } = useTable<any, HttpError, { description: string; category: IProdotti; createdAt: [Dayjs, Dayjs] }>({
        resource: "getFornitori",

        filters: {
            permanent: [
                {
                    field: "type",
                    operator: "eq",
                    value: 'tesr',
                }
            ]
        }
    });

    useEffect(() => {
        if (reload) {
            window.location.reload();
        } else {
            setReload(false);
        }
        setReload(false);
    }, [reload])

    const columns: ColumnsType<any> = [
        {
            title: 'P.IVA/C.F.',
            dataIndex: 'vatNumber',
            key: 'vatNumber',
            width: '10%',
            render: (value, record: any) => (
                value
            )
        },
        {
            title: 'Nominativo',
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            render: (value, record: any) => (
                value
            )
        },
        {
            title: 'Telefono',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: '5%',
            render: (value, record: any) => (
                value
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '10%',
            render: (value, record: any) => (
                value
            )
        },
        {
            title: 'Azioni',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (value, record: any) => (
                <>
                    <Space>
                        <Tooltip placement="top" title={'Modifica'}>
                            <Button htmlType="button" type="primary" onClick={() => updateFornitore(record)}
                                    icon={<EditOutlined />} style={{backgroundColor: '#fdbe01', border: 'none'}} />
                        </Tooltip>
                        <Tooltip placement="top" title={'Elimina'}>
                            <Button htmlType="button" type="primary" onClick={() => deleteFornitore(record)}  icon={<DeleteOutlined />} danger/>
                        </Tooltip>
                    </Space>

                </>
            )
        }
    ];

    const updateFornitore = (record: any) => {
        setIsUpdate(true);
        form.resetFields();
        form.setFieldsValue(record)
        setOpen(true)
    };

    const delFornitore = useDelete<any>();

    const deleteFornitore = (record: any) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Vuoi davvero eliminare il Fornitore selezionato?',
            okText: 'Si',
            cancelText: 'Annulla',
            onOk: () => {
                delFornitore.mutate({
                    resource: "deleteFornitore",
                    id: record.id,
                });
                setReload(true)
            }
        });
    };

    const postFornitore = () => {
        form.resetFields();
        setIsUpdate(false);
        setOpen(true);
    };

    const { mutate } = useCreate<any>();
    const updFornitore = useUpdate<any>();

    const onFinish = (values: any) => {
        console.log('Received values of form: ', values);
        if (!isUpdate) {
            mutate({
                resource: "postFornitore",
                values: values,
            });
        } else {
            updFornitore.mutate({
                resource: "updateFornitore",
                id: values.id,
                values: values,
            });
        }
        setOpen(false);
        setTimeout(() => {
            setReload(true)
        }, 1000)
    };

    return (
        <>
            <div>
                <Button htmlType="button" type="primary" onClick={() => postFornitore()} style={{
                    marginRight: 10, marginBottom: 10
                }}>
                    Nuovo Fornitore
                </Button>
                <Table {...tableProps} rowKey="id" columns={columns} scroll={{ y: 650 }} size="small" />
            </div>
            <Modal
                title="Creazione Nuovo Fornitore"
                centered
                visible={open}
                onOk={() => form.submit()}
                onCancel={() => setOpen(false)}
                okText={isUpdate ? 'Modifica': 'Conferma'}
                width={1000}
            >
                <Form
                    form={form}
                    layout={'vertical'}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={24}>
                        <Col span={12} key={'vatNumber'}>
                            <Form.Item
                                wrapperCol={{ span: 8 }}
                                name={'id'}
                                label={'Id'}
                                hidden={true}
                            >
                                <Input placeholder="id" />
                            </Form.Item>
                            <Form.Item
                                name={'idAccount'}
                                label={'idAccount'}
                                hidden={true}
                            >
                                <Input placeholder="idAccount" />
                            </Form.Item>
                            <Form.Item
                                name={'vatNumber'}
                                label={'Partita Iva'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Devi Inserire una Partita Iva!',
                                    },
                                ]}
                            >

                                <Input placeholder="piva" />
                            </Form.Item>
                        </Col>
                        <Col span={12} key={'taxCode'}>
                            <Form.Item
                                name={'taxCode'}
                                label={'Codice Fiscale'}
                            >

                                <Input placeholder="cf" />
                            </Form.Item>
                        </Col>
                        <Col span={24} key={'name'}>
                            <Form.Item
                                name={'name'}
                                label={'Nominativo'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Devi Inserire un Nominativo!',
                                    },
                                ]}
                            >

                                <Input placeholder="Nominativo" />
                            </Form.Item>
                        </Col>
                        <Col span={12} key={'street'}>
                            <Form.Item
                                name={'street'}
                                label={'Indirizzo'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Devi Inserire un Indirizzo!',
                                    },
                                ]}
                            >

                                <Input placeholder="Indirizzo" />
                            </Form.Item>
                        </Col>
                        <Col span={12} key={'city'}>
                            <Form.Item
                                name={'city'}
                                label={'Città'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Devi Inserire una Città!',
                                    },
                                ]}
                            >

                                <Input placeholder="Città" />
                            </Form.Item>
                        </Col>
                        <Col span={8} key={'country'}>
                            <Form.Item
                                name={'country'}
                                label={'Nazione'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Devi Inserire una Nazione!',
                                        max: 2
                                    },
                                ]}
                            >

                                <Input placeholder="Nazione" defaultValue={'IT'} />
                            </Form.Item>
                        </Col>
                        <Col span={8} key={'district'}>
                            <Form.Item
                                name={'district'}
                                label={'Provincia'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Devi Inserire una Provincia!',
                                        max: 2
                                    },
                                ]}
                            >

                                <Input placeholder="Provincia" />
                            </Form.Item>
                        </Col>
                        <Col span={8} key={'zipcode'}>
                            <Form.Item
                                name={'zipcode'}
                                label={'Cap'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Devi Inserire un Cap!',
                                    },
                                ]}
                            >

                                <Input placeholder="Cap" />
                            </Form.Item>
                        </Col>
                        <Col span={12} key={'phoneNumber'}>
                            <Form.Item
                                name={'phoneNumber'}
                                label={'Telefono'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Devi Inserire un Telefono!',
                                    },
                                ]}
                            >

                                <Input placeholder="Telefono" />
                            </Form.Item>
                        </Col>
                        <Col span={12} key={'email'}>
                            <Form.Item
                                name={'email'}
                                label={'Email'}
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Devi Inserire un Email!',
                                    },
                                ]}
                            >

                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

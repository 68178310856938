import React, {useContext, useEffect, useState} from 'react';
import { Typography } from "antd";

import {StoreContext} from '../../contexts/StoreContext';
import {apiFunction} from '../../apiFuntion';

const { Text } = Typography;
type SelectProps = {
    onSelect: () => void;
};

export const StoreSelect: React.FC<SelectProps> = ({ onSelect }) => {
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const [userPermission, setUserPermission] = value2;

    useEffect(() => {
        apiFunction.getUserData().then( (u: any) => {
            // console.log(u)
            setUserPermission(u);
        });
    }, []);

    return (
        <Text>{(userPermission?.rivenditori?.ragioneSociale ? '' + userPermission?.rivenditori?.ragioneSociale : 'Admin')}</Text>
    );
};

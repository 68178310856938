import { useGetIdentity } from "@refinedev/core";


import { Layout as AntdLayout, Space, Typography } from "antd";
// import { useTranslation } from "react-i18next";
import { StoreSelect } from '../../select/StoreSelect';

const { Text } = Typography;

export const Header: React.FC = () => {
  // const { i18n } = useTranslation();
  // const locale = useGetLocale();
  // const changeLanguage = useSetLocale();
  const { data: user } = useGetIdentity<{ id: number; email: string }>();

  // const currentLocale = locale();

  // const menu = (
  //   <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
  //     {[...(i18n.languages || [])].sort().map((lang: string) => (
  //       <Menu.Item
  //         key={lang}
  //         onClick={() => changeLanguage(lang)}
  //         icon={
  //           <span style={{ marginRight: 8 }}>
  //             <Avatar size={16} src={`/images/flags/${lang}.svg`} />
  //           </span>
  //         }
  //       >
  //         {lang === "en" ? "English" : "German"}
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  // );

  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "0px 24px",
        height: "64px",
        backgroundColor: "#FFF",
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
    >
      {/*<Dropdown overlay={menu}>*/}
      {/*  <Button type="link">*/}
      {/*    <Space>*/}
      {/*      <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />*/}
      {/*      {currentLocale === "en" ? "English" : "German"}*/}
      {/*      <DownOutlined />*/}
      {/*    </Space>*/}
      {/*  </Button>*/}
      {/*</Dropdown>*/}
      <StoreSelect onSelect={() => { }}
      />
      <Space style={{ marginLeft: "8px" }}>
        {user?.email && (
          <Text style={{ display: 'flex', flexDirection: 'column' }} ellipsis strong>
            {'>>>'} {user.email}
          </Text>
        )}
      </Space>
    </AntdLayout.Header>
  );
};

import {
    TextField,
    useEditableTable,
    useSelect,
    useTable,
    TagField,
    useModalForm,
    EditButton,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Switch,
    Table,
    Input,
    Space,
    Button,
    Form,
    Popover,
    notification,
    Select,
    Tooltip,
    Modal,
} from "antd";

import type { ColumnsType } from 'antd/es/table';
import { IProdotti } from 'interfaces';
import axios from 'axios';
import { TOKEN_KEY } from '../../constants';

import {
    CrudFilters,
    useApiUrl,
    useCreate,
    useNavigation,
    useNotification,
    useUpdate,
} from "@refinedev/core";

import React, { useEffect, useRef, useState } from 'react';
import { Dayjs } from "dayjs";
import { InputRef, Progress } from 'antd';
// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import "./styles.css";
const _ = require('lodash');
export const ArticoliList: React.FC = (props) => {
    const apiUrl = useApiUrl();
    const [description, setDescription] = useState('');
    const [externalId, setExternalId] = useState('');
    const [category, setCategory] = useState('');
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [form] = Form.useForm();
    const editProdotto = useUpdate();
    const updateStock = useCreate();
    const [data, setData] = useState<any>();
    const [barcode, setBarcode] = useState<any>();
    const [stock, setStock] = useState<boolean>(false);
    const inputRef = useRef<InputRef>(null);
    const { open } = useNotification();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<IProdotti>() || [];
    const { list } = useNavigation();

    const { tableProps, tableQueryResult, searchFormProps, isEditing, setId } = useEditableTable<IProdotti>({
        resource: "getProdottis",
        meta: { populate: '*' },
        permanentFilter: [
            {
                field: "description",
                operator: "containss",
                value: description,
            },
            {
                field: "externalId",
                operator: "containss",
                value: externalId,
            },
            {
                field: "barcode",
                operator: "containss",
                value: barcode,
            },
            {
                field: "category",
                operator: "containss",
                value: category,
            },
            {
                field: "stock",
                operator: "eq",
                value: stock,
            },
        ],
        onSearch: (params: any) => {
            setCategory(params.category);
            const filters: CrudFilters = [];
            filters.push(
                {
                    field: "description",
                    operator: "containss",
                    value: description,
                },
                {
                    field: "category",
                    operator: "containss",
                    value: params.category,
                },
            );
            return filters;
        },
    });

    const { selectProps: categorySelectProps } = useSelect<any>({
        resource: "getCategorie",
        optionValue: (item) => item.value,
        optionLabel: (item) => item.label,

        pagination: {
            mode: "server"
        }
    });

    function updateSoglia(value: any, record: any) {
        updateStock.mutate({
            resource: "updateStocks",
            values: {
                idProduct: record.id,
                idProductVariant: record.stocks.idProductVariant,
                warningLevel: Number(value)
            }
        })
        if (updateStock.isSuccess) tableQueryResult.refetch().then();

    }

    const resetFilter = () => {
        setCategory('');
        setDescription('');
        setExternalId('');
        setBarcode('');
        form.resetFields();
    };

    const showModal = (r: any) => {
        setData(r);
        setIsModalOpen(true);
    };

    const deleteModal = (r: any) => {
        setData(r);
        if (barcode) {
            setIsDeleteModalOpen(true);
        } else {
            open?.({
                message: 'Devi selezionare un Barcode',
                description: '',
                key: Math.random().toString(),
                type: 'progress'
            });
        }

    };
    const handleOk = () => {
        console.log(data, barcode);
        if (barcode && barcode.length > 0) {
            if (data && data?.barcodes?.length > 0) {
                data.barcodes.push({
                    value: barcode,
                    salable: true
                })
            } else {
                data.barcodes = [{
                    value: barcode,
                    salable: true
                }]
            }
            editProdotto.mutate({
                resource: "editProdottos",
                id: data.id,
                values: {
                    data
                }
            })
            console.log(data);
            setConfirmLoading(true);
            setIsModalOpen(false);
            setBarcode(null);
        } else {
            open?.({
                message: 'Devi inserire un Barcode',
                description: '',
                key: Math.random().toString(),
                type: 'progress'
            });
        }
    };

    const handleDeleteOk = () => {
        console.log(data, barcode);
        const index = data.barcodes.findIndex((x: any) => x.value === barcode);
        data.barcodes.splice(index, 1);
        data.defaultTare = 2000
        console.log(tableQueryResult.data?.data)
        // editProdotto.mutate({
        //     resource: "editProdottos",
        //     id: data.id,
        //     values: {
        //         data
        //     }
        // })
        setIsDeleteModalOpen(false);
        setBarcode(null);
        setConfirmLoading(true);
    };

    const handleSave = (e: any) => {
        console.log(e);
        setBarcode(e.target.value);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleDeleteCancel = () => {
        setIsDeleteModalOpen(false);
    };

    function onChange(record: any, checked: boolean) {
        axios.post(apiUrl + '/updateStocks', {
            data: {
                idProduct: record.id,
                idProductVariant: record.stocks.idProductVariant,
                manageStock: checked,
                warningLevel: record.stocks.warningLevel
            }
        }, { headers: { "Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY) } }).then((res: any) => {
            console.log(res);
            window.location.reload();
        });
    }

    function onChangeProdotto(data: any, checked: any, tare: number) {
        console.log(data, checked, tare)
        if (checked) data.soldByWeight = checked;
        if (tare > 0) data.defaultTare = Number(tare);
        editProdotto.mutate({
            resource: "editProdottos",
            id: data.id,
            values: {
                data
            }
        })
    }


    const columns: ColumnsType<any> = [
        {
            title: 'Codice',
            dataIndex: 'externalId',
            key: 'externalId',
            width: '5%',
            render: (value, record: any) => (
                value?.split('_')[0]
            )
        },
        {
            title: 'Descrizione',
            dataIndex: 'descriptionLabel',
            key: 'descriptionLabel',
            render: (value, record: any) => (
                value
            )
        },
        {
            title: 'Prezzo',
            dataIndex: 'prices',
            key: 'prices',
            width: '8%',
            render: (value, record: any) => (
                value?.[0]?.value.toLocaleString('de-DE', { maximumFractionDigits: 2 }) + ' €'
            )
        },
        {
            title: 'Magazzino',
            dataIndex: 'stocks',
            key: 'stocks',
            width: '6%',
            render: (value, record: IProdotti) => (
                <Switch defaultChecked={Boolean(record?.stocks?.manageStock)} checkedChildren="SI" unCheckedChildren="NO" onChange={(checked, event) => onChange(record, checked)} />
            )
        },
        {
            title: 'Soglia',
            dataIndex: 'stocks',
            key: 'warningLevel',
            width: '5%',
            render: (value, record: IProdotti) => {
                // if (isEditing(record.id)) {
                //     return (
                //         <Form.Item
                //             name="warningLevel"
                //             style={{ margin: 0 }}
                //         >
                //             <Input placeholder={record?.stocks?.warningLevel?.toString()} onPressEnter={(e:any) => updateSoglia(e.target.value, record)}/>
                //         </Form.Item>
                //     );
                // }
                // return <TextField value={record?.stocks?.warningLevel}/>
                return (record?.stocks?.warningLevel);
            }
        },
        {
            title: 'Soglia Kg',
            dataIndex: 'warningLevel',
            key: 'warningLevel_kg',
            width: '5%',
            className: 'rowColorBlu',
            render: (value, record: IProdotti) => (
                (record?.stocks && record?.stocks?.warningLevel) ? record?.stocks?.warningLevel * (record.peso / 1000) : 0
            )
        },
        {
            title: 'Giacenza',
            dataIndex: 'stockLevel',
            key: 'stockLevel',
            width: '5%',
            render: (value, record: IProdotti) => (
                record?.stocks?.quantity
            )
        },
        {
            title: 'Giacenza Kg',
            dataIndex: 'stockLevel',
            key: 'stockLevel_kg',
            width: '5%',
            className: 'rowColorBlu',
            render: (value, record: IProdotti) => (
                (record?.stocks && record?.stocks?.quantity) ? Number(record?.stocks?.quantity * (record.peso / 1000)).toFixed(2) : 0
            )
        },
        {
            title: 'Incoming',
            dataIndex: 'incomingQuantity',
            key: 'incomingQuantity',
            width: '5%',
            render: (value, record: IProdotti) => (
                record?.stocks?.incomingQuantity
            )
        },
        {
            title: 'Incoming Kg',
            dataIndex: 'incomingQuantity',
            key: 'incomingQuantity_kg',
            width: '5%',
            className: 'rowColorBlu',
            render: (value, record: IProdotti) => (
                (record?.stocks && record?.stocks?.incomingQuantity) ? record?.stocks?.incomingQuantity * (record.peso / 1000) : 0
            )
        },
        // {
        //     title: 'Peso',
        //     dataIndex: 'soldByWeight',
        //     key: 'soldByWeight',
        //     width: '5%',
        //     render: (value, record: IProdotti) => (
        //         <Switch defaultChecked={Boolean(record?.soldByWeight)}
        //                 checkedChildren="SI"
        //                 unCheckedChildren="NO"
        //                 onChange={(checked, event) => onChangeProdotto(record, checked, 0)}/>
        //     )
        // },
        // {
        //     title: 'Tara',
        //     dataIndex: 'defaultTare',
        //     key: 'defaultTare',
        //     width: '8%',
        //     render: (value, record: IProdotti) => (
        //         value
        //     )
        // },
        {
            title: 'Categoria',
            dataIndex: 'category',
            key: 'category',
            responsive: ['md'],
            render: (value, record: any) => (
                value.description
            )
        },
        // {
        //     title: 'Barcodes',
        //     dataIndex: 'barcodes',
        //     key: 'barcodes',
        //     responsive: ['md'],
        //     render: (value, record: any) => {
        //         console.log(value);
        //         return <>
        //             <Space>
        //                 <Select
        //                     placeholder="Vedi Barcodes"
        //                     allowClear={true}
        //                     options={value}
        //                     optionLabelProp={"value"}
        //                     size={"middle"}
        //                     style={{width: 200}}
        //                     onChange={(e) => setBarcode(e)}
        //                 />
        //                 <Tooltip placement="top" title={"Aggiungi Barcodes"}>
        //                     <Button type="primary" icon={<PlusOutlined/>} size={'middle'}
        //                             onClick={() => showModal(record)}/>
        //                 </Tooltip>
        //                 <Tooltip placement="top" title={"Elimina Barcodes"}>
        //                     <Button type="primary" icon={<MinusOutlined/>} size={'middle'}
        //                             onClick={() => deleteModal(record)}/>
        //                 </Tooltip>
        //             </Space>
        //             <Modal
        //                 title="Nuovo Barcode"
        //                 visible={isModalOpen}
        //                 onOk={handleOk}
        //                 onCancel={handleCancel}
        //                 okText="Conferma"
        //                 cancelText="Annulla"
        //             >
        //                 <Input ref={inputRef} value={barcode} placeholder={"Scrivi Barcode"} allowClear
        //                        onChange={(e) => {
        //                            handleSave(e)
        //                        }}/>
        //             </Modal>
        //             <Modal
        //                 title="Elimina Barcode"
        //                 visible={isDeleteModalOpen}
        //                 onOk={handleDeleteOk}
        //                 onCancel={handleDeleteCancel}
        //                 okText="Conferma"
        //                 cancelText="Annulla"
        //             >
        //                 <span>{barcode}</span>
        //             </Modal>
        //         </>
        //     }
        // },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 'actions',
            responsive: ['md'],
            render: (value, record: any) => (
                <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => editModalShow(record.id)}
                />
            )
        },
    ];

    const {
        modalProps: editModalProps,
        formProps: editFormProps,
        show: editModalShow,
    } = useModalForm<IProdotti>({
        action: "create",
        resource: "updateStocks",
        queryOptions: {
            enabled: false,
        },
        warnWhenUnsavedChanges: false,
        redirect: 'list'
    });

    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    const onTableChange = () => {
        console.log('CCCC')
    };

    return (
        <>
            <div>
                <div hidden={!isLoading} style={{ marginTop: 30 }}>
                    <Progress
                        strokeColor={{
                            from: '#108ee9',
                            to: '#87d068',
                        }}
                        percent={((isLoading) ? value : 99)}
                        status={((isLoading) ? 'active' : 'success')}
                    />
                </div>
                <Popover
                    placement="leftTop"
                    style={{
                        float: "right"
                    }}
                    content={<Form form={form} layout="horizontal" {...searchFormProps} labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}>
                        <Form.Item label="Descrizione" name="description">
                            <Input
                                placeholder="Descrizione"
                                prefix={<Icons.SearchOutlined />}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Codice AMS" name="externalId">
                            <Input
                                placeholder="Codice Ams"
                                prefix={<Icons.SearchOutlined />}
                                onChange={(e) => setExternalId(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Barcode" name="barcode">
                            <Input
                                placeholder="Barcode"
                                prefix={<Icons.SearchOutlined />}
                                onChange={(e) => setBarcode(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Category" name="category">
                            <Select {...categorySelectProps} allowClear={true}
                            />
                        </Form.Item>
                        <Form.Item label="In Stock" name="sotck" valuePropName={'checked'}>
                            <Switch onChange={(e) => setStock(e)} />
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <Button htmlType="submit" type="primary" onClick={() => handleVisibleChange(false)}>
                                    Filter
                                </Button>
                                <Button htmlType="button" type="primary" onClick={resetFilter}>
                                    Reset
                                </Button>
                            </Space>

                        </Form.Item>
                    </Form>}
                    title="Custom Filter"
                    trigger="click"
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                >
                    <Button type="primary" style={{ marginBottom: 15 }}>Filter</Button>
                </Popover>
                <Table
                    {...tableProps}
                    onRow={(record) => ({
                        onClick: (event: any) => {
                            console.log(record);
                            setSelectedProduct(record);
                        },
                    })}
                    rowKey="id"
                    columns={columns}
                    scroll={{ y: 650 }}
                    size="small" />
                <Modal {...editModalProps} >
                    <Form {...editFormProps} layout="vertical" onFinish={(values: any) => {
                        console.log(values);
                        updateSoglia(values.warningLevel, selectedProduct);
                        editModalShow(0);
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500)
                    }}>
                        <Form.Item
                            label={'Soglia'}
                            name="warningLevel"
                            style={{ margin: 0 }}
                        >
                            {/*<Input placeholder={record?.stocks?.warningLevel?.toString()} onPressEnter={(e:any) => updateSoglia(e.target.value, record)}/>*/}
                            <Input placeholder={selectedProduct?.stocks?.warningLevel?.toString()} />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </>
    );
};
